import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { ISite } from "@/typings/models/v2/site";
import { SiteAPI } from "@/services/api/site.v2.api";
import { NotificationService } from "@/services/notifications";
import { useSiteTagCategoriesStore } from "@/store/tag-categories.store";
import { usePreferencesStore } from "@/store/preferences.store";
import { UsersAPIV2 } from "@/services/api/users.v2.api";
import { IUserViews } from "@/views/forecast/typings/views";
import { useUserStore } from "@/store/user.store";

export const useSiteStore = defineStore("site", () => {
  const preferencesStore = usePreferencesStore();
  const userStore = useUserStore();
  const siteStore = useSiteStore();
  const siteTagCategoriesStore = useSiteTagCategoriesStore();

  const site = ref<ISite | null>(null);
  const userViewsForCurrentSite = ref<IUserViews>({} as IUserViews);
  const isFetching = ref<boolean>(false);

  const fetchViews = async () => {
    try {
      userViewsForCurrentSite.value = await UsersAPIV2.getViewsByUserIdAndSiteId(userStore.currentUser.id, siteStore.site!.id);
    } catch (e: unknown) {
      NotificationService.error("Error occurred while fetching views");
      throw e;
    }
  };

  const fetchSite = async (siteId: number) => {
    isFetching.value = true;

    try {
      site.value = await SiteAPI.getById(siteId, {
        relations: [
          "configSiteGlobal",
          "configSiteMl",
          "configSiteOr",
          "partners",
        ],
      });
    } catch (e: any) {
      NotificationService.error("Error occurred while fetching current site");
      throw e;
    } finally {
      isFetching.value = false;
    }

    // site-related tag categories can be accessible from Tactical and F&R (tags filter, columns)
    // it should be always ready-to-use when we initialize the app or change a site
    preferencesStore.preferences.lastUsedSiteId = siteId;
    preferencesStore.save();

    await Promise.all([fetchViews(), siteTagCategoriesStore.fetchCategories()]);

    window.gtag("config", import.meta.env.VITE_GA_ID, {
      custom_map: { dimension2: "is_supplier" },
    });

    window.gtag("event", "custom_dimension_is_supplier", {
      is_supplier: site.value!.isSupplierView,
    });
  };

  const isDemoSite = computed(() => site.value && [501, 502].includes(site.value.id));

  return {
    site,
    isFetching,
    isDemoSite,
    userViewsForCurrentSite,
    fetchSite,
    fetchViews,
  };
});
