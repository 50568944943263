import { useUserStore } from "@/store/user.store";
import { JimoWindow } from "./jimo.window";

declare let window: JimoWindow;

export const JimoService = {
  initialize(email: string) {
    // it means Jimo is already initialized
    if (window.jimo != null) return; 

    window.jimo = [];
    window.JIMO_PROJECT_ID = import.meta.env.VITE_JIMO_PROJECT_ID;
    window.JIMO_MANUAL_INIT = true;

    const s = document.createElement("script");
    s.async = true;
    s.type = "text/javascript";
    s.setAttribute("src", "https://undercity.usejimo.com/jimo-invader.js");

    // we wait for the script to load and then initialize Jimo
    s.onload = () => {
      if (window.jimoInit) {
        window.jimo.push(["do", "identify", [email]]);
        window.jimo.push(["set", "user:name", [email]]);
        window.jimoInit();
      }
    };

    document.head.appendChild(s);
  },

  push: (args: any) => {
    const userStore = useUserStore();

    if (window.jimo) {
      window.jimo.push(["set", "core:language", [ userStore.currentUser.locale ]]);
      window.jimo.push(args);
    }
  },
};
