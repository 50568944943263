import { API_V2 } from "@/services/api";
import { AxiosRequestConfig } from "axios";
import { ICompany } from "@/typings/models/v2/company";
import {
  IGetCompaniesParams,
  IGetCompanyParams,
  IGetCompanySitesPayload,
} from "@/services/api/types/companies.api.v2.types";
import { IGetCompanyPromotions } from "@/services/api/types/promotions.api.v2.types";
import { IGetCompanyTagsParams } from "@/services/api/types/tags.api.v2.types";
import { IPaginatedResponse, IWithOptionalPattern, IWithOptionalSelect, IWithPagination } from "@/typings/models/v2/api";
import { IPromotion } from "@/typings/models/promotion";
import { ISite } from "@/typings/models/v2/site";
import { IProductSupplierLink } from "@/typings/models/v2/supplier";
import { ITag } from "@/typings/models/v2/tag";
import { useCompanyStore } from "@/store/company.store";
import { IGetCompanyTagCategoriesParams } from "@/services/api/types/tag-categories.api.v2.types";
import { ITagCategory } from "@/typings/models/v2/tag-category";

export const CompaniesAPIV2 = {
  async getCompanies(
    params?: IGetCompaniesParams,
  ) {
    const { data } = await API_V2.get<ICompany[]>("/api/v2/companies", params);
    return data;
  },
  async getCompany(
    id: number,
    params?: IGetCompanyParams,
  ) {
    const { data } = await API_V2.get<ICompany>(
      `/api/v2/companies/${id}`,
      params,
    );

    return data;
  },
  async getCompanyPromotions(companyId: number, params: IGetCompanyPromotions) {
    const { data } = await API_V2.get<IPaginatedResponse<IPromotion>>(
      `/api/v2/companies/${companyId}/promotions`,
      params,
    );

    return data;
  },

  async getCompanySuppliers(
    companyId: number,
    params: IWithPagination & IWithOptionalPattern & IWithOptionalSelect<IProductSupplierLink> & { siteId?: number },
  ): Promise<IPaginatedResponse<IProductSupplierLink>> {
    const { data } = await API_V2.get<IPaginatedResponse<IProductSupplierLink>>(
      `/api/v2/companies/${companyId}/suppliers`,
      params,
    );

    return data;
  },

  async getCompanySites(payload?: IGetCompanySitesPayload) {
    const companyStore = useCompanyStore();

    const { data } = await API_V2.post<ISite[]>(
      `/api/v2/companies/${companyStore.company!.id}/sites/get`,
      payload,
    );

    return data;
  },

  async getCompanyTags(
    companyId: number,
    params: IGetCompanyTagsParams,
    config?: AxiosRequestConfig,
  ) {
    const { data } = await API_V2.get<IPaginatedResponse<ITag>>(
      `/api/v2/companies/${companyId}/tags`,
      params,
      config,
    );

    return data;
  },

  async getCompanyTagCategories(
    companyId: number,
    params: IGetCompanyTagCategoriesParams,
    config?: AxiosRequestConfig,
  ) {
    const { data } = await API_V2.post<IPaginatedResponse<ITagCategory>>(
      `/api/v2/companies/${companyId}/tag-categories/get`,
      params,
      config,
    );

    return data;
  },
};
