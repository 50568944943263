import "@/assets/styles/tailwind/tailwind.css";
import "@/assets/styles/index.scss";
import "@/polyfills";
import { createApp } from "vue";
import FloatingVue from "floating-vue";
import { createPinia } from "pinia";
import { useI18n } from "vue-i18n";
import posthog from "posthog-js";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import App from "@/App.vue";
import { router } from "@/router";
import { i18n } from "@/i18n/i18n@next";
import { broadcaster } from "@/services/broadcast-channel";
import { authClientInit } from "@/store/auth.store";
import { clickOutsideDirective } from "@/directives/click-outside";
import { initializeChartJS } from "@/components/chart";
import { mutationObserverDirective } from "@/directives/mutation";
import { resizeObserverDirective } from "@/directives/resize";
import { scrolledDirective } from "@/directives/scrolled";
import { injectSvgSprite } from "@/assets/svg/inject";
import { usePreferencesStore } from "@/store/preferences.store";
import { initDayjsExtensions } from "@/utils/dayjs";

const pinia = createPinia();
// initialize auth0 client
authClientInit();

initializeChartJS();
initDayjsExtensions();

broadcaster.init();

posthog.init(
  "phc_asmO4PP7Y8jesDSH906hudEhKvNls8K8n1LFPQiFxe8",
  {
    // remove the condition if needed to test PostHog autocapture locally
    autocapture: import.meta.env.MODE !== "development",
    api_host: "https://posthog.app.flowlity.com",
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  },
);

export const app = createApp(App, {
  setup() {
    const { t, locale } = useI18n({
    });

    return { t, locale };
  },
});

if (import.meta.env.VITE_APM_ACTIVE) {
  app.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: "frontend",
      serverUrl: import.meta.env.VITE_APM_SERVER_URL,
      active: import.meta.env.VITE_APM_ACTIVE ?? false,
      environment: import.meta.env.MODE,
      logLevel: "warn",
      breakdownMetrics: true,
    },
  });
}

app.use(pinia);
app.use(i18n);
app.use(router);
app.use(FloatingVue);

const preferencesStore = usePreferencesStore();
preferencesStore.load();
injectSvgSprite();

app.directive("click-outside", clickOutsideDirective);
app.directive("mutation", mutationObserverDirective);
app.directive("resize", resizeObserverDirective);
app.directive("scrolled", scrolledDirective);

app.mount("#app");

export default app;
