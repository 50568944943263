export const downloadFile = (blob: Blob, fileName: string) => {
  // Create a download link
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName;
    
  // Trigger the download
  document.body.appendChild(link);
  link.click();
    
  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(downloadUrl);
};
