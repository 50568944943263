import { defineStore } from "pinia";
import { ref } from "vue";
import { ICompany } from "@/typings/models/v2/company";
import { CompaniesAPIV2 } from "@/services/api/companies.v2.api";
import { NotificationService } from "@/services/notifications";
import { useUserStore } from "@/store/user.store";
import { usePreferencesStore } from "@/store/preferences.store";

export const useCompanyStore = defineStore("company", () => {
  const preferencesStore = usePreferencesStore();
  const userStore = useUserStore();

  const company = ref<ICompany | null>();
  const isFetching = ref<boolean>(false);

  const fetchCompany = async (companyId: number) => {
    isFetching.value = true;

    try {
      company.value = await CompaniesAPIV2.getCompany(companyId, {
        relations: ["sites"],
        select: {
          id: true,
          name: true,
          autoFillSupply: true,
          allowSftpExport: true,
          sites: {
            id: true,
            name: true,
          },
        },
      });
    } catch (e: any) {
      NotificationService.error("Error occurred while fetching the company data");
      throw e;
    } finally {
      isFetching.value = false;
    }

    preferencesStore.preferences.lastUsedCompanyId = companyId;
    preferencesStore.save();

    if (!userStore.currentUser.isSuperAdmin) {
      const userSiteIds = userStore.currentUser.sites.map(site => site.id);
      company.value.sites = company.value.sites.filter(site => userSiteIds.includes(site.id));
    }
  };

  return {
    company,
    isFetching,
    fetchCompany,
  };
});
